import NcImage from 'shared/NcImage/NcImage';
import { Helmet } from 'react-helmet';
import { FormEvent, useState } from 'react';
import { uid } from 'uid';
import Input from 'shared/Input/Input';
import ButtonCircle from 'shared/Button/ButtonCircle';
import { doc, setDoc } from 'firebase/firestore';
import { DB } from 'utils/firestore';

const MakelaarsPage = () => {
	const [email, setEmail] = useState<string>('');

	const submitMakelaarsForm = async (e: FormEvent) => {
		e.preventDefault();
		const _makelaar = {
			id: uid(12),
			email: email,
		};
		setIsSending(true);
		await setDoc(doc(DB, 'makelaars', _makelaar.id), { ..._makelaar })
			.then(() => {
				setEmail('');
				setIsDone(true);
				setIsSending(false);
			})
			.catch((err) => {
				console.log(err);
				setIsSending(false);
			});
	};

	const [isSending, setIsSending] = useState(false);

	const [isDone, setIsDone] = useState(false);

	const renderHeader = () => {
		return (
			<header className='container rounded-xl'>
				<div className='max-w-screen-md mx-auto space-y-5'>
					<h1
						className=' text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl '
						title='Quiet ingenuity: 120,000 lunches and counting'
					>
						Makelaars
					</h1>
					<span className='block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
						turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
					</span>

					<div className='w-full border-b border-neutral-100 dark:border-neutral-800'></div>
				</div>
			</header>
		);
	};

	const renderContent = () => {
		return (
			<div
				id='single-entry-content'
				className='prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark'
			>
				<h3>Title</h3>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
					turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
					fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus
					elit sed risus. Maecenas eget condimentum velit, sit amet feugiat
					lectus. Class aptent taciti sociosqu ad litora torquent per conubia
					nostra, per inceptos himenaeos. Praesent auctor purus luctus enim
					egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex.
					Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum
					lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in
					elementum tellus.
				</p>
			</div>
		);
	};

	return (
		<div className='nc-PageSingle pt-8 lg:pt-16  '>
			<Helmet>
				<title>Makelaars</title>
			</Helmet>
			{renderHeader()}
			<div className='flex mb-6 justify-between gap-5 max-w-screen-md mx-auto flex-wrap md:flex-nowrap'>
				<div className='w-full p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'>
					<h3 className='text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
						80%
					</h3>
					<span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400'>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit.
					</span>
				</div>

				<div className='w-full p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'>
					<h3 className='text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
						11.550
					</h3>
					<span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400'>
						Lorem ipsum dolor sit amet, consectetur.
					</span>
				</div>

				<div className='w-full p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800'>
					<h3 className='text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200'>
						15 mensen
					</h3>
					<span className='block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400'>
						Lorem ipsum dolor sit amet.
					</span>
				</div>
			</div>

			<div className='nc-SingleContent container space-y-10 max-w-screen-md mx-auto '>
				{renderContent()}
				<div className='flex w-full'>
					<NcImage
						className='w-full rounded-xl object-none h-[300px] '
						containerClassName='container '
						src={'/forsale-house.png'}
					/>
					<NcImage
						className=' w-full rounded-xl object-none h-[300px] '
						containerClassName='container  '
						src={'/ImmoMystique_assets/door-key.png'}
					/>
				</div>
				{renderContent()}
				{/* {renderTags()} */}

				{/* {renderAuthor()}
					{renderCommentForm()}
					{renderCommentLists()} */}
				<div>
					<p>E-mail</p>
					<form
						onSubmit={(e) => submitMakelaarsForm(e)}
						className='mt-2 relative max-w-sm'
					>
						<Input
							required
							aria-required
							placeholder='E-mailadres'
							type='email'
							rounded='rounded-full'
							value={email}
							changeValue={setEmail}
						/>
						<ButtonCircle
							type='submit'
							className='absolute transform top-1/2 -translate-y-1/2 right-[5px]'
							disabled={isSending}
						>
							{isDone && email === '' ? (
								<i className='las las la-check  text-xl'></i>
							) : (
								<i className='las la-arrow-right text-xl'></i>
							)}
						</ButtonCircle>
					</form>
				</div>
				<div className='max-w-screen-md mx-auto border-neutral-100 dark:border-neutral-700 pb-12'></div>
			</div>
		</div>
	);
};

export default MakelaarsPage;
